import "./App.css";
import {
  BrowserRouter as Router,
} from "react-router-dom";
import { Header } from "./components/header";
import { Content } from "./components/content";

function App() {
  return (
    <div className="h-full flex flex-col align-center justify-center">
      <Router>
        <Header />
        <Content />
      </Router>
    </div>
  );
}

export default App;
